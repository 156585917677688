.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.text-center {
  text-align: center;
}

.call-modal .ant-drawer-header {
  padding: 0;
}

.call-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
}

.call-modal__title {
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 15px;
  h3 {
    margin: 0;
  }
  span {
    cursor: pointer;
  }
}

.call-modal__button {
  animation-name: call-modal__animation;
  animation-duration: 1s;
  animation-iteration-count: 6;
}

.call-modal__client {
  display: flex;
  justify-content: space-between;
}

.call-modal__client-show {
  padding: 10px;
  cursor: pointer;
  justify-content: flex-end;
}

.call-modal__clients {
  display: flex;
  margin-top: 26px;
  justify-content: center;
}

.call-modal__clients-table {
  padding: 15px;
}

@keyframes call-modal__animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.seperator-wrapper {
  width: 100%;
  .seperator {
    width: 1400px;
    height: 5px;
    animation: rotate 3s infinite linear;
    -webkit-animation: rotate 3s infinite linear;
  }
}

@-webkit-keyframes rotate {
  from {
    background-position: -3000px;
  }
  to {
    background-position: 0px;
  }
}

@keyframes rotate {
  from {
    background-position: -1400px;
  }
  to {
    background-position: 0px;
  }
}

.gradient {
  background: rgb(48, 255, 144); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(48, 255, 144, 1) 0%,
    rgba(237, 45, 237, 1) 25%,
    rgba(201, 152, 38, 1) 50%,
    rgba(48, 255, 230, 1) 75%,
    rgba(48, 255, 144, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(48, 255, 144, 1)),
    color-stop(25%, rgba(237, 45, 237, 1)),
    color-stop(50%, rgba(201, 152, 38, 1)),
    color-stop(75%, rgba(48, 255, 230, 1)),
    color-stop(100%, rgba(48, 255, 144, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(48, 255, 144, 1) 0%,
    rgba(237, 45, 237, 1) 25%,
    rgba(201, 152, 38, 1) 50%,
    rgba(48, 255, 230, 1) 75%,
    rgba(48, 255, 144, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(48, 255, 144, 1) 0%,
    rgba(237, 45, 237, 1) 25%,
    rgba(201, 152, 38, 1) 50%,
    rgba(48, 255, 230, 1) 75%,
    rgba(48, 255, 144, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(48, 255, 144, 1) 0%,
    rgba(237, 45, 237, 1) 25%,
    rgba(201, 152, 38, 1) 50%,
    rgba(48, 255, 230, 1) 75%,
    rgba(48, 255, 144, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(48, 255, 144, 1) 0%,
    rgba(237, 45, 237, 1) 25%,
    rgba(201, 152, 38, 1) 50%,
    rgba(48, 255, 230, 1) 75%,
    rgba(48, 255, 144, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30ff90', endColorstr='#30ff90',GradientType=1 ); /* IE6-9 */
}

.floating-button {
  position: fixed;
  top: 100px;
  right: 20px;
  width: 60px;
  height: 60px;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.container {
  margin: 30vh auto;
  position: relative;
  width: 100px;
  height: 100px;
}

.mapIcon,
.iconPing {
  background: #57e600;
  border-radius: 50%;
  position: absolute;
}

.mapIcon {
  /*  fix for iconPing border  */
  margin: 29%;
  width: 50%;
  height: 50%;
}

.iconPing {
  width: 100%;
  height: 100%;
  background: #333;

  /* if changing the border width, change the margin on mapIcon */
  border: 4px groove #55ff14;
  border-style: double dotted;
  animation-name: ping;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  /* W3C and Opera */
  -moz-animation-iteration-count: infinite;
  /* Firefox */
  -webkit-animation-iteration-count: infinite;
  /*Safari and Chrome*/
}

.ping2 {
  opacity: 0;
  animation-delay: 1.3s;
  border-style: dotted double;
  // .ping3 {
  // }
  opacity: 0;
  animation-delay: 2.3s;
}

@keyframes ping {
  from {
    display: block;
    transform: scale(0.4);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
