@charset "UTF-8";
@import 'Variables';

// @font-face{
//   font-family: $defaultFont;
//   font-weight: normal;
//   font-style: normal;
// }

*::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px transparent;
}
// Input number buttons
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  // margin: 0;
  margin-left: 5px;
}

.text-center {
  text-align: center;
}

.ml-1 {
  margin-left: 1rem;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  text-rendering: optimizeLegibility;

  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: sans-serif;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: $color-secondary-light;
  font-size: 14px;
  min-width: 1200px;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.card-image {
  display: block;
  height: 260px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &.thumb {
    height: 90px;
  }
}

li.ant-menu-item {
  padding: 0 10px !important;
}

.card-checkbox {
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-block !important;
  margin-bottom: 0 !important;
  width: auto;
  line-height: 15px;
}

.task-ribbon {
  top: 225px;
  right: 15px !important;
  padding: 3px 10px;
  height: auto !important;
  background-color: rgba(#000, 0.5);
  .ant-ribbon-corner {
    display: none;
  }
}

.ant-modal-wrap {
  z-index: 1050;
}

.MainIcon {
  display: flex;
  align-items: center;
  font-style: normal;

  svg {
    padding: 0;
    margin: 0;
    width: 16px;
    height: 16px;
    fill: #73849a;
  }
}

.MainIcon.logo {
  svg {
    fill: #007bff;
  }
}

.full-width-skeleton {
  display: block;
  width: 100%;

  & > span {
    width: 100% !important;
  }
}

.contentBox {
  padding: 15px 0;
  font-size: 16px;

  &.flight-box {
    border-top: 1px solid #f7f4f4;
  }

  .row {
    margin-bottom: 8px;
    color: #000;
    display: flex;
    align-items: center;

    svg {
      fill: $color-secondary;
      margin-right: 10px;
    }

    .weight {
      color: $color-success;
      margin-left: auto;
      margin-right: 10px;
    }
  }

  .close {
    cursor: pointer;
  }

  .flight-row {
    svg {
      fill: $color-primary;
    }

    .date {
      color: $defaultColor;
      margin-left: auto;
      margin-right: 10px;
    }
  }

  .row:last-child {
    margin-bottom: 0;
  }
}

.modal-scrollbar {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding-right: 10px;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 15px;
    border-left: 15px solid transparent;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $defaultColor;
    border-radius: 15px;
    border-left: 25px solid $defaultColor;
  }
}

.radio-flight {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  border: 1px solid $defaultColorCheckbox;
  margin-bottom: 10px;
  border-radius: 5px;

  & > span:not(.ant-radio) {
    display: inline-flex;
    width: 100%;
  }
}

.radio-space-between {
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    & > span:not(.ant-radio) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 150px;
    }
  }
}

.radio-buttons {
  display: block;
  padding: 0 16px;

  .radio-button {
    color: $orderIcon;
    fill: $orderIcon;
    font-weight: 500;
    outline: none;
    box-shadow: none;

    & > span:not(.ant-radio-button) {
      display: flex;
      align-items: center;
      width: 100%;

      .MainIcon {
        margin-right: 7px;
      }
    }

    &.ant-radio-button-wrapper-checked {
      border-color: transparent !important;
      color: $color-primary;
      fill: $color-primary;
      background-color: $color-primary-light;
      box-shadow: none;
    }
  }
}

.ant-radio-button-wrapper-checked .MainIcon svg {
  fill: $color-primary;
}

.MainTable .ant-table-content table {
  min-height: 100vh;
}

.simple-table {
  .ant-table {
    table {
      border-radius: 0;
      border-collapse: initial;

      tr {
        th,
        td {
          border-radius: 0 !important;
          border: 0 !important;
          border-bottom: 1px solid #f0f0f0 !important;
        }
      }
    }
  }
}
.bordered {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.mb-10 {
  margin-bottom: 10px;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.address-content {
  display: flex;
  margin-bottom: 15px;

  .MainIcon {
    margin-right: 10px;
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.active-card {
  transition: all 0.3s;
  cursor: pointer;
  margin-bottom: 15px;

  &:hover,
  &.hover {
    border-color: $color-primary;

    .MainIcon {
      svg {
        fill: $color-primary;
      }
    }
  }
}

.detail-application-tooltip {
  width: 325px;

  .ant-popover-inner-content {
    padding: 15px 0;
  }
}

.ant-primary-border {
  color: $color-primary;
  background: #fff;
  border-color: $color-primary;
}

.button-with-icon {
  display: flex;
  align-items: center;

  .MainIcon {
    margin-right: 5px;
  }
}

textarea {
  border-radius: $defaultBorderRadius !important;
  resize: none;
}

.btn {
  height: 38px;
}

.btn-theme {
  text-align: center;
  border-radius: 5px;
  height: 30px;
  line-height: 28px;
  vertical-align: middle;

  &.btn-block {
    display: block;
  }

  &.btn-default {
    border: 1px solid $borderColor;
  }
}

.logo {
  height: 30px;
  width: 30px;
  fill: $color-primary;

  svg {
    height: 30px;
    width: 30px;
  }
}

.navbar {
  justify-content: unset;
  line-height: unset;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 999;
  position: fixed;
  width: 100%;
  padding: $defaultPadding;
  height: 60px;
  background: #fff;
  display: flex;
  align-items: center;

  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

  .logoPlace {
    margin-right: 20px;
  }

  #basic-navbar-nav {
    transition: $mainTransition;
    border: 0;
    height: 100%;
    width: 900px;

    li {
      font-size: 13px;
      margin: 0;
      border: 0;
      height: 100%;
      padding: 0;
      top: 0;

      a {
        padding: 1px 14px 11px;
        display: block;
        height: 100%;
        color: $defaultColor;
      }

      &:hover {
        background: $color-primary-light;

        a {
          color: $color-primary;
        }

        svg {
          fill: $color-primary;
        }
      }
    }

    svg {
      width: 20px;
      height: 20px;
      display: block;
      margin: 0.5rem auto 0.1rem;
      fill: $color-secondary;
      transition: $mainTransition;
    }

    .activeSvg,
    .activeLink {
      svg {
        fill: $color-primary;
      }
    }
  }

  .activeLink {
    background: $color-primary-light;
    color: $color-primary !important;
  }

  .navbar-nav .nav-link {
    transition: $mainTransition;
    height: 60px;
    padding: 0 0.8rem;
  }

  .buttonAdd {
    margin-right: 4px;
    margin-left: auto;
    background: $color-primary;
    display: flex;
    height: 38px;
    padding: 0 15px 0 10px;
    align-items: center;
    font-size: 16px;
    border-radius: $defaultBorderRadius;

    .name {
      line-height: 16px;
      margin: 0;
    }
  }

  .buttonAdd svg {
    fill: #fff;
    width: 17px;
    margin-right: 15px;
    height: 17px;
  }

  .userInfo {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .notification {
    //Temporarily disable
    display: none;
    margin: 0 10px;
    position: relative;
  }

  .notification svg {
    margin-right: 10px;
    width: 22px;
    height: 22px;
    display: block;
    fill: $color-secondary;
    transition: $mainTransition;
  }

  .activeNotification:after {
    content: '';
    position: absolute;
    background: #fc5a5a;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    right: 5px;
    top: -6px;
  }

  .userName {
    margin-left: 10px;

    .ant-dropdown-trigger {
      display: flex;
      color: #212121;
      border: 1px solid #eaedf3;
      background-color: #fff;
      align-items: center;
      height: 38px;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-radius: 0.25rem;

      &:hover {
        border-color: #007bff;
        color: #007bff;
      }

      span {
        margin-right: 5px;
      }
    }
  }
}

.formSearch {
  margin-right: 24px !important;
}

.navbar .userName .ant-dropdown-trigger svg {
  width: 10px;
  height: 5px;
  margin-left: 2px;
  fill: $color-secondary;
}

.navbar .userName .userName-active {
  border: 1px solid #08c908;
  outline: 1px solid #08c908;
}

.navbar .userName .userName-inactive {
  border: 1px solid #ff5151;
  outline: 1px solid #ff5151;
}

.ant-dropdown-menu-item {
  transition: $mainTransition;
  padding: 6px 20px;
}

.ant-dropdown-menu-item:hover {
  background: $color-secondary-light;
}

.ant-dropdown-menu-item a {
  //color: $defaultColor;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-item a:hover {
  color: $defaultColor;
}

.ant-dropdown-menu {
  padding: 14px 0;
  border-radius: 10px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu {
  svg {
    margin-right: 14px;
    width: 16px;
    height: 18px;
    fill: $color-secondary;
  }
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 22px;
  top: 2px;
}

.ant-dropdown-arrow {
  width: 12px;
  height: 12px;
  border-width: 8px;
}

.inputsSearch {
  position: relative;
  svg {
    position: absolute;
    fill: $mainCollapse;
    width: 18px;
    height: 18px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 10px;
    z-index: 1;
  }

  input {
    width: 105%;
    min-width: 300px;
    line-height: 38px;
    letter-spacing: 0.2px;
    font-size: 16px;
    background: $color-secondary-light;
    border: 0;
    border-radius: $defaultBorderRadius;
    padding: 0 14px 0 32px;

    &::-webkit-input-placeholder {
      color: #b2becd;
    }
  }

  &.inputsSearch-small {
    margin-left: 15px;

    svg {
      width: 14px;
      height: 14px;
    }

    input {
      width: 100%;
      padding-left: 30px;
      padding-top: 2px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;

      &::-webkit-input-placeholder {
        font-size: 12px;
      }
    }
  }

  &__closable {
    position: absolute;
    right: 35px;
    top: 19px;

    svg {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
}

.ant-input::placeholder,
.ant-input-textarea::placeholder,
.ant-input-number-input::placeholder {
  color: $mainCollapse;
  font-size: 16px;
}

.ant-input-number-input {
  font-size: 16px;
}

.ant-select-selection-placeholder {
  font-size: 16px;
  color: $mainCollapse;
  opacity: 1;
}

.ant-select-selection-item {
  font-size: 16px;
}

.ant-select-show-arrow {
  .ant-select-selection-item {
    padding-right: 0.5rem !important;
  }
}

.without-padding-drawer {
  .ant-drawer-body {
    padding: 10px 0;

    .ant-tabs-nav-wrap {
      padding-left: 25px;
    }
  }
}

.client-orders-tab {
  .order-table-row {
    padding: 10px 25px;
    align-items: center;
  }
}

.no-header-table {
  thead {
    display: none;
  }
  .ant-table-content {
    table {
      min-height: auto;

      tr {
        & > td {
          padding: 10px 5px;

          &:last-child {
            padding-right: 23px;
          }

          &:first-child {
            padding-left: 23px;
          }
        }

        &:not(.combined-order-item) {
          & > td {
            &:nth-child(2) {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
.ant-picker-ranges {
  .ant-picker-ok {
    display: block;
    float: none;
    margin-left: 0;

    .ant-btn {
      display: block;
      width: 100%;
    }
  }
}

// loader spinner

.spinner {
  bottom: 0;
  left: 0;
  top: 0;
  position: absolute;
  display: block;
  border-radius: 50%;
  animation: spinLoader 500ms infinite linear;
  will-change: transform;
  right: 0;
  margin: auto;
}

.spinner-sm {
  width: 18px;
  height: 18px;
  border: 0.15rem solid transparent;
}

.spinner-md {
  width: 22px;
  height: 22px;
  border: 0.2rem solid transparent;
}

.spinner-lg {
  width: 32px;
  height: 32px;
  border: 0.22rem solid transparent;
}

.spinner-primary {
  border-color: rgba(255, 255, 255, 0.3);
  border-top-color: #eaeaea;
}

@-webkit-keyframes spinLoader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinLoader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.branch-dropdown {
  padding: 0 15px 10px;
  border-bottom: 1px solid $color-secondary-light;

  .branch-title {
    color: $color-primary;
    font-weight: 500;
  }

  .branch-subtitle {
    color: $defaultColor;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

//Custom Checkbox

//Custom Checkbox
.itemColSecond {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointer {
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.itemColSecond input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  right: 20px;
  height: 16px;
  width: 16px;
  background-color: $defaultColorCheckbox;
}

.checkmark-round {
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.itemColSecond:hover input ~ .checkmark {
  background-color: $defaultColorCheckbox;
}

/* When the checkbox is checked, add a blue background */
.itemColSecond input:checked ~ .checkmark {
  background-color: $color-primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

//Select number Group
.ant-input-group-addon {
  padding: 0 4px;
  border-top-left-radius: $defaultBorderRadius;
  border-bottom-left-radius: $defaultBorderRadius;
  background: #fff;

  .ant-select-selection-item {
    font-size: 16px;
  }

  .ant-select-arrow {
    top: 16px;
  }

  .iconSelectNumber {
    width: 14px;
    height: 8px;
    fill: #d9d9d9;
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 4px;
  }
}

.ant-input-wrapper input {
  border-left: 0 !important;
}

/* Show the checkmark when checked */
.itemColSecond input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.itemColSecond .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.sideItemNameCheckbox {
  color: $itemCount;
  font-size: 15px;
  position: relative;
}

.subname {
  margin-top: 5px;
  margin-left: 26%;
}

//Region Select Style

.selectRegionBoxSecond {
  margin-left: 20px;

  label {
    color: $color-secondary;
  }
}

.selectRegionBox,
.selectRegionBoxSecond {
  display: flex;
  flex-direction: column;

  label {
    color: $color-secondary;
  }

  .selectRegionBox
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: $defaultBorderRadius !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 94%;
    height: 38px !important;
    padding: 0 11px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 36px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .selectInputRegion {
    width: 200px !important;
    height: 38px !important;

    .ant-select-arrow {
      z-index: 99;
      display: inline-block;
      font-style: normal;
      text-transform: none;
      vertical-align: -0.125em;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      top: 20px;
      right: 11px;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 12px;
      line-height: 1;
      text-align: center;
      pointer-events: none;
    }

    .ant-select-clear {
      position: absolute;
      top: 20px;
      right: 11px;
      z-index: 1;
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      color: rgba(0, 0, 0, 0.25);
      font-size: 12px;
      font-style: normal;
      line-height: 1;
      text-align: center;
      text-transform: none;
      background: #fff;
      cursor: pointer;
      opacity: 0;
      -webkit-transition: color 0.3s ease, opacity 0.15s ease;
      transition: color 0.3s ease, opacity 0.15s ease;
      text-rendering: auto;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: $defaultBorderRadius;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 38px !important;
    padding: 0 11px;
  }
}

.Street {
  margin-top: 0;
  margin-left: 20px;
}

// modal

.ant-modal-body {
  padding: 0;

  .confirmText {
    padding: 1rem;
  }
}

.ant-modal-content {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: $cardBorderRadius;

  .ant-modal-header {
    color: #212121;
    position: relative;
    padding: 1rem;
    border-top-left-radius: $cardBorderRadius;
    border-top-right-radius: $cardBorderRadius;

    &:after {
      content: '';
      position: absolute;
      background-color: rgba(0, 0, 0, 0.15);
      display: block;
      z-index: 15;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      transform-origin: 50% 100%;
      transform: scaleY(calc(1 / 2));
    }
  }

  .header {
    display: flex;

    h5 {
      font-size: 22px;
      margin-bottom: 6px;
    }

    .steps {
      display: flex;
      margin-left: 56px;

      .step {
        margin-right: 20px;
        align-items: center;
        display: flex;
      }

      .text {
        transition: $mainTransition;
        color: $color-secondary;
        margin: 0 12px;
      }

      .check {
        transition: $mainTransition;
        position: relative;
        width: 26px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 50%;
        border: 1px solid #d8dce6;
      }

      .check:before {
        line-height: 24px;
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      .step:nth-child(1) .check:before {
        content: '1';
      }

      .step:nth-child(2) .check:before {
        content: '2';
      }

      .step:nth-child(3) .check:before {
        content: '3';
      }

      .step:nth-child(4) .check:before {
        content: '4';
      }

      .step:nth-child(4) {
        margin-right: 0;
      }

      svg {
        width: 8px;
        height: 14px;
        fill: $mainCollapse;
      }

      .checked {
        display: none;
      }

      .successStep {
        .check {
          border: 1px solid $color-primary-light;
          background: $color-primary-light;
          color: $color-primary;
        }

        .MainIcon {
          margin: auto;
        }

        .checked {
          display: flex;
          height: 100%;

          svg {
            fill: $color-primary;
            width: 12px;
            height: 12px;
          }
        }

        .text {
          color: $color-primary;
        }

        .check:before {
          color: transparent;
        }
      }
    }
  }
}

.myCheckboxExtra .ant-checkbox-inner {
  background: $defaultColorCheckbox;
  border: none;

  &:hover {
    border: none;
  }
}

.myCheckboxExtra .ant-checkbox-checked::after,
.myCheckboxExtraFirst .ant-checkbox-checked::after {
  border: none;
}

.myCheckboxExtra .ant-checkbox-inner,
.myCheckboxExtraFirst .ant-checkbox-inner {
  border-radius: 0.2rem;
}

.myCheckboxExtra .ant-checkbox-inner::after {
  left: 25%;
  width: 6px;
  height: 10px;
}

.ant-radio-inner::after {
  .myCheckboxExtra,
  .myCheckboxExtraFirst {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    position: relative;
    height: 16px;
    margin-top: 5px;

    .checkName {
      padding-left: 10px;
    }

    .ant-checkbox {
      margin-top: 4px;
      height: auto;
      border: none;
    }

    span {
      display: flex;
      flex-direction: row;
      margin-top: 0;

      .price {
        position: absolute;
        right: 20px;
        color: $color-primary;
      }
    }
  }
}

.ant-modal-close-x {
  display: flex;

  i {
    margin: auto;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: #a1adbd;
  }
}

.ant-modal-footer {
  font-size: 16px;
  padding: 20px;
  display: flex;
  justify-content: flex-end;

  .rightBtns {
    display: flex;

    p {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  button:first-child {
    box-shadow: none;
    border: 0;
    color: $defaultColor;
  }

  .ant-btn-primary {
    // background: #007bff;
    // border-color: #007bff;
  }

  button {
    border-radius: $defaultBorderRadius;
    height: 38px;
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
    }
  }
}

.myCheckboxExtra {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  position: relative;
  height: 16px;
  margin-top: 5px;

  .ant-checkbox {
    margin-top: 4px;
    height: auto;
  }

  span {
    display: flex;
    flex-direction: row;
    margin-top: 0;

    .price {
      position: absolute;
      right: 35px;
      color: $color-primary;
    }
  }
}

.sender-mine {
  border-top: 1px solid #eaedf3;
  margin-top: 10px;
  position: relative;

  .line {
    opacity: 0.15;
    position: absolute;
    right: 0;
    width: 100%;
  }
}

.Settings {
  margin-top: 80px;
  padding: 0 20px;

  .structure {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    grid-gap: 1.5vw;
  }
}

.clients-radio-button {
  width: 100%;
  display: block;

  &__item {
    display: flex !important;
    align-items: center;
    border: 1px solid $borderColor;
    padding: 2px 7px;
    border-radius: 5px;
    margin-bottom: 10px !important;
    margin-right: 0 !important;

    &.ant-radio-wrapper-checked {
      border-color: $color-primary;
      background-color: $color-primary-light;
    }

    & > span:not(.ant-radio) {
      display: block;
      width: 100%;
    }
  }
}

.customDefaultSelect {
  position: relative;

  .ant-select-selector {
    border-radius: $defaultBorderRadius !important;
  }

  svg {
    z-index: 9;
    width: 14px;
    height: 8px;
    fill: #d9d9d9;
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 8px;
  }
}

.has-sidebar {
  margin-left: 20px;
  margin-top: 80px;
  width: calc(100% - 320px);
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  position: absolute;
  bottom: -25px;
  background: #f5f5f5;
  z-index: 10;
}

.usd {
  &::before {
    content: '$';
  }
}

.modalForm {
  width: 100%;
  padding: 0 20px;
}

.hidden {
  display: none;
}

.spaceSkeleton {
  background: #fff;
  display: flex;
  width: 100%;
  margin-bottom: 4px;
  padding: 20px;
  border-radius: $defaultBorderRadius;

  .ant-space {
    margin-right: 30px;
  }

  .ant-space-item {
    width: 100%;

    .ant-skeleton {
      width: 100%;

      .ant-skeleton-button {
        height: 22px;
        width: 100%;
      }
    }
  }

  .ant-space-item:last-child {
    margin-right: 0 !important;
  }
}

.spaceSkeleton:last-child {
  margin-bottom: 0;
}

.dots {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;

  span {
    border-radius: 50%;
    position: absolute;
    background: $color-secondary;
    width: 4px;
    height: 4px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.dots span:after {
  right: -8px;
  background: $color-secondary;
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 4px;
  height: 4px;
}

.dots span:before {
  left: -8px;
  background: $color-secondary;
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 4px;
  height: 4px;
}

.col-sidebar-button {
  position: absolute;
  z-index: 10000;
  top: 0px;
  cursor: pointer;
  background-color: white;
  border: 0px;
  padding: 10px 15px;
}

.col-sidebar {
  margin-bottom: 50%;
  padding-top: 50px;
  top: 60px;
  position: fixed;
  font-size: 16px;
  width: 280px;
  flex: 0 0 280px;
  max-width: 280px;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  height: calc(100vh - 130px);
  overflow-y: scroll;
  overflow-x: auto;
  right: 0;

  -webkit-box-shadow: -1px 0 2px rgba(0, 0, 0, 0.05);
  box-shadow: -1px 0 2px rgba(0, 0, 0, 0.05);

  .ant-collapse {
    background: #fff;
    border: 0;
  }

  .ant-checkbox {
    border-radius: 50% !important;
  }

  .ant-checkbox-inner {
    border: none;
    border-radius: 50px !important;
    background: $defaultColorCheckbox;
  }

  .ant-checkbox-checked {
    border-radius: 50px;

    &::after {
      border-radius: 50%;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: $color-primary;
}

.ant-collapse-content-active > .ant-collapse-content-box {
  border-bottom: 1px solid #f1f1f1;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 10px 6px 15px;
}

//side Item
.side-item {
  width: 100%;
  height: 1px;
  background: transparent;
  border: none !important;
  outline: none !important;
}

.side-item-button {
  width: 100%;
  padding: 15px;
  height: 36px;
  background: transparent;
  border: none !important;
  outline: none !important;
  margin-bottom: 10px;
}

.myPrintButton {
  width: 240px;
  height: 38px;
  background: $color-success;
  color: white;
  border-radius: 0.5rem;
  margin-bottom: 10px;
  text-align: center;
}

.myPrintButton:hover {
  color: #fff;
}

.panelItem {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.col-sidebar .ant-collapse-header {
  padding: 0 16px !important;
  padding-right: 42px !important;
}

.itemUp {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: right;
  margin-top: 6px;
  margin-bottom: 6px;
  border: none;
  align-items: center;

  .switch-filter {
    position: absolute;
    right: 17px;
  }
}

.itemUpSwitch {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: none;
  padding: 10px 0 10px 16px;
  border-bottom: 1px solid #eaedf3;

  .ant-switch-small {
    margin-right: 16px;
    margin-left: auto;
  }
}

.ant-form {
  display: flex;
  margin-right: 6px;
}

.ant-form-item {
  display: block;
}

.ant-form-item:last-child {
  margin-bottom: 0;
}

.ant-form-item-label label {
  color: $color-secondary;
}

.ant-picker {
  height: 38px;
  border-radius: $defaultBorderRadius;
}

.ant-collapse-content {
  border: none !important;
  padding-top: 0 !important;
}

.IconDiv {
  margin-right: 8px;
  height: 16px;
}

.SVGIcon {
  width: 16px;
  height: 16px;
  fill: $color-secondary;
}

.iconKg svg {
  height: 20px;
}

.IconPrint {
  margin-left: 14px;
  width: 18px;
  height: 16px;
  fill: white;
}

.nameClassBtn {
  font-size: 16px;
  color: white !important;
  margin: auto;
  padding-right: 14px;
}

.itemName,
.itemNameSecond {
  margin-bottom: 0;
}

.itemName {
  margin-bottom: 0;
  font-size: 16px;
  color: $color-secondary;
}

.itemNameSecond {
  color: $itemCount;
  text-transform: uppercase;
  font-weight: 600;

  &:focus {
    color: $color-primary !important;
  }

  &:active {
    color: $color-primary !important;
  }
}

.itemCount {
  margin-left: auto;
  font-size: 1rem;
  font-weight: 600;
  color: $itemCount;
  text-align: right;
}

.itemCol,
.itemColSecond {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  margin-top: 0 !important;
}

.itemCol {
  padding: 0 32px 2px 27px;
}

.itemColSecond {
  margin-left: 0 !important;
  padding: 0 6px 0 2px;
  color: $itemCount !important;
}

.sideItemName,
.sideItemNameSecond {
  font-size: 15px !important;
  margin-bottom: 0 !important;
}

.sideItemName {
  color: $color-secondary;
}

.sideItemNameSecond {
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sideItemCount,
.sideItemCountZero,
.sideItemCountTwo {
  margin-left: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: right;
}

.ant-collapse-content label:after {
  content: '';
  position: absolute;
  background: $color-primary-light;
  height: 100%;
  width: 280px;
  z-index: 1;
  left: -15px;
  opacity: 0;
}

.grouped-row {
  .ant-table-selection-column {
    display: none;
  }

  td:not(.ant-table-selection-column) {
    background-color: transparent;
    padding: 5px 0;
    left: 0 !important;
  }

  &:hover {
    td {
      background-color: transparent !important;
    }
  }
}

.ant-checkbox-wrapper-checked:after {
  opacity: 1 !important;
}

.ant-collapse-content span {
  z-index: 2;
  position: relative;
}

.sideItemCountZero {
  padding: 4px 0;
  display: flex;
  align-items: center;
  width: 100%;

  span:last-child {
    order: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-checkbox {
    order: 2;
    margin-left: auto;
  }

  .ant-checkbox + span {
    padding: 0;
  }
}

label:after {
  transition: $mainTransition;
}

.ant-collapse-item-active .itemUp p {
  color: $color-primary;
}

.ant-collapse-item-active .noColor p {
  color: $color-secondary;
}

//bottomSide

.side-item-bottom,
.side-item-bottom-fixed,
.side-item-bottom-sub {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  line-height: 30px;
  height: 25px;
  background: transparent;
  border: none !important;
  outline: none !important;
}

.side-item-bottom-fixed {
  align-items: center;
  display: flex;
  padding: 0;
  bottom: 0;
  z-index: 100;
  background: #fff;
  position: fixed;
  width: 280px;
  height: 70px;
  border-top: 1px solid #eaedf3 !important;
  -webkit-box-shadow: -1px 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: -1px 0 2px rgba(0, 0, 0, 0.1);

  .nameClass {
    margin: auto;
    padding-right: 14px;
  }
}

.sideItemBottomName {
  flex: 3;
  margin-left: 10px;
  text-align: left;
  color: $itemCount;
  font-weight: 600;
}

.IconRight {
  margin-left: 14px;
  width: 16px;
  height: 16px;
  fill: $color-secondary;
}

.myButton {
  border-radius: $defaultBorderRadius;
  height: 38px;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  margin: 10px auto;
  background: $color-success !important;
  border: transparent;
  padding: 0;

  svg {
    fill: #fff;
  }
}

.myButtonTwo {
  border: 1px solid #d8dce6;
  border-radius: $defaultBorderRadius;
  height: 38px;
  display: flex;
  align-items: center;
  color: $defaultColor;
  width: calc(100% - 32px);
  margin: 10px auto;
  background: transparent;
  padding: 0;

  span {
    margin-left: 10px;
  }
}

.myButton:hover {
  color: white !important;
}

.myButton:focus {
  color: white !important;
}

.myButtonTwo {
  background: transparent !important;
}

.nameClass,
.nameClassTwo {
  font-size: 16px !important;
}

.nameClass {
  color: #212529 !important;
}

.nameClassTwo {
  color: white;
}

.sideCollapseItemSecond {
  margin-top: 15px;
  display: flex;
  padding-left: 21px;
  flex-direction: column;
}

.sideItemNameSecondSmall {
  font-size: 14px;
  margin-left: 60px;
  margin-top: 2px;
  color: $defaultColor;
}

.panelCheckbox {
  padding: 4px 0;

  .ant-collapse-content-box {
    border-bottom: 0;
  }
}

.panel-switch {
  &.ant-collapse-item-disabled > .ant-collapse-header {
    cursor: auto;
  }
}

.panelCheckbox .ant-checkbox-group {
  width: 100%;
}

.CustomArrow {
  .ant-collapse-header {
    .anticon {
      display: none !important;
    }
    .arrowDown {
      fill: $mainCollapse;
      transition: $mainTransition;
      position: absolute;
      width: 14px;
      height: 14px;
      right: 17px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .ant-collapse-item-active {
    .arrowDown {
      transform: rotate(180deg);
    }
  }
}

.little-arrow {
  transition: $mainTransition;
  margin-left: 5px;

  svg {
    width: 10px;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.actionDrop {
  .ant-dropdown-menu-item {
    span {
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }
  .ant-dropdown-menu-submenu-title {
    display: flex;
    min-width: 250px;
    align-items: center;
    transition: 0.2s all ease;
    padding: 6px 20px;

    span {
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }

  &.ant-menu-horizontal > .ant-menu-submenu {
    margin: 0;
  }

  .checkbox svg {
    fill: $color-success;
  }

  .status svg {
    fill: $color-primary;
  }

  .box svg {
    fill: $color-violet;
  }

  .airplane svg {
    fill: $color-warning;
  }

  .print svg {
    fill: #43484d;
  }

  .addProd svg {
    fill: $color-violet;
  }
  .creditCard svg {
    fill: $color-primary;
  }
  .finance svg {
    fill: $color-primary;
  }
  .sms svg {
    fill: $colorViolet;
  }
  .cargo svg {
    fill: $color-success;
  }
  .delete svg {
    fill: $color-danger;
  }
  .print svg {
    fill: $color-violet;
  }
}

.ant-menu-submenu-popup {
  .ant-menu-item {
    border-bottom: 1px solid $borderColor;
    span {
      display: flex;
      align-items: center;

      .MainIcon {
        margin-right: 5px;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

// .ant-form-item-has-error .ant-input{
//   border: 1px solid #ff4d4f !important;
// }

.ant-input-affix-wrapper {
  padding: 0 11px;
  margin-bottom: 15px;

  input {
    padding: 0;
    border: 0;
  }
}

.ant-select:not(.ant-select-multiple) .ant-select-selector {
  border-radius: $defaultBorderRadius !important;
  height: 38px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: block;
  margin-right: 0;
  position: absolute;
  right: -10px;
  top: 2px;
}

.ant-form-item-label > label::after {
  display: none;
}

.ant-form-item-label {
  overflow: visible;
}

.ant-form {
  display: block;
  margin-right: 0;
}

.ant-input,
.ant-input-number-input {
  height: 38px;
  border-radius: $defaultBorderRadius;
}

.ant-input-number {
  height: 38px;
  width: 100%;
  border-radius: $defaultBorderRadius;
}

.ant-input {
  font-size: 16px;
}

.ant-col label:not(.ant-checkbox-group-item) {
  height: unset;
  color: $color-secondary;
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
}

.ant-modal-footer .ant-btn {
  height: 38px;
  span {
    font-size: 16px;
  }
}

.default-modal {
  .ant-modal-body {
    padding: 20px;
  }
}

.deletePlaceButton {
  border: 0;
  box-shadow: none;
  color: $color-danger;
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 16px;
  height: 38px;

  svg {
    margin-right: 8px;
    fill: $color-danger;
  }
}

.contentCategories .actionContainer {
  width: 100%;
}

.skeleton-bg {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
}

.change-status-menu,
.print-menu {
  border: 0;

  .ant-menu-item {
    padding: 0;

    span {
      display: flex;
      align-items: center;
    }
  }
}

.item-action-bar-popover {
  z-index: 9999;
}

.action-bar-popover {
  .ant-popover-inner-content {
    padding: 8px 16px 5px 16px;

    .ant-menu-item {
      height: 30px;
      line-height: 30px;
    }
  }
}

.action-drop-horizontal {
  border-bottom: 0 !important;

  .checkbox svg {
    fill: $color-success;
  }

  .status svg {
    fill: $color-primary;
  }

  .box svg {
    fill: $color-violet;
  }

  .airplane svg {
    fill: $color-warning;
  }

  .print svg {
    fill: #43484d;
  }

  .addProd svg {
    fill: $color-violet;
  }

  .creditCard svg {
    fill: $color-primary;
  }

  .finance svg {
    fill: $color-primary;
  }

  .sms svg {
    fill: $colorViolet;
  }

  .cargo svg {
    fill: $color-success;
  }

  .delete svg {
    fill: $color-danger;
  }

  .print svg {
    fill: $color-violet;
  }

  & > .ant-menu-item {
    margin: 0 10px 0 0 !important;
    span span {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      border-radius: 5px;
      border: 1px solid #ced4da;
      padding: 0.3rem 0.6rem;
      line-height: 1.5;

      i {
        margin-right: 10px;

        &.MainIcon svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    &:hover,
    &.ant-menu-item-active,
    &.ant-menu-item-selected {
      color: #212121;
      border-bottom-color: transparent !important;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.d-flex {
  display: flex;

  &.no-wrap {
    flex-wrap: nowrap;
  }

  &.inline {
    display: inline-flex;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-content-end {
    justify-content: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  &.flex-column {
    flex-direction: column;
  }
}

.payment-tabs {
  .ant-tabs-nav-list {
    margin-left: 20px;
  }
}

.word-break {
  word-break: break-all;
}

.two-line-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs-in-modal {
  .ant-tabs-nav-list {
    margin-left: 20px;
  }
}

.input-size-button {
  height: 38px;
}

.app-type-radio {
  &-button {
    width: 100%;
    height: 150px;
    border: 1px solid $color-secondary;
    border-radius: 15px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.4s;

    .MainIcon {
      width: 100%;
      margin-bottom: 20px;
      svg {
        width: auto;
        height: 35px;
        fill: $color-secondary;
      }
    }

    &:hover {
      background-color: $color-primary-light;
      color: $color-primary;
      border-color: $color-primary;

      .MainIcon {
        svg {
          fill: $color-primary;
        }
      }
    }
  }
}

.full-width-checkbox {
  display: flex !important;
  align-items: center !important;

  & > span:not(.ant-checkbox) {
    flex-basis: auto !important;
    flex: 1 1 auto !important;
  }
}

.application-wrapper {
  overflow-y: scroll;
  height: calc(100vh - 80px);
}

.actionContainer {
  align-items: center;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: calc(100% - 280px);
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fff;
  padding: 0 20px;
  margin-left: 0;
  user-select: none;
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.08);

  @media (min-width: 750px) and (max-width: 1024px) {
    width: 80vw;
  }

  .itemCountAction {
    color: $color-primary;
    font-weight: 500;
  }

  .itemChosen {
    color: $itemCount;
    font-size: 18px;
    //margin-top: 15px;
    margin-left: 0px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  .iconButton {
    margin-right: 8px;
  }

  .iconButton-primary {
    width: 13px;
    height: 18px;
    fill: $color-primary;
    opacity: 1 !important;
  }

  .iconButton-violet {
    width: 18px;
    height: 18px;
    fill: $colorViolet;
  }

  .iconButton-Box {
    width: 18px;
    height: 18px;
    fill: $color-violet;
  }

  .iconButton-Airplane {
    width: 18px;
    height: 18px;
    fill: $color-warning;
  }

  .iconButton-Print {
    width: 18px;
    height: 18px;
    fill: $color-secondary;
  }

  .iconButton-Kargo {
    width: 18px;
    height: 18px;
    fill: $color-success;
  }

  .iconButton-finance {
    width: 18px;
    height: 18px;
    fill: $color-primary;
  }

  .iconButton-delete {
    width: 18px;
    height: 18px;
    fill: $color-danger;
  }

  .myButtonAction {
    border: 1px solid #7e8fa4;
    border-radius: 5px;
    padding: 0 10px;
    height: 40px;
    margin-top: 5px;
  }

  .nameButton {
    color: $itemCount !important;
    opacity: 1 !important;
    margin-bottom: 0;
  }

  .cancel-action {
    border-color: transparent;
  }
}

.ant-table-placeholder {
  .ant-table-cell {
    vertical-align: top;
  }
}
.ant-table-column-sorters {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ant-table-thead {
  th.ant-table-column-sort {
  }
}

.grid-menu {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  &.cols-6 {
    li {
      flex: 0 0 calc(100% / 6);
      max-width: calc(100% / 6);
      padding: 2px 3px;
      border: 1px solid $color-secondary;

      svg {
        margin-right: 0;
      }
    }
  }
}

.choose-category-popover {
  z-index: 1050;
  width: 850px;

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-select-show-search {
    width: 100%;
  }
}

.ant-select-item-option {
  padding: 7px 15px;
  border-bottom: 1px solid $color-secondary-light;
  color: $color-secondary;
  font-weight: 500;
}

.no-wrap {
  flex-wrap: nowrap;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.batches-table {
  tr {
    td,
    th {
      padding: 3px 7px;
      vertical-align: top;

      .dots {
        position: relative;
      }
    }
  }
}

.modalDots {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  .dots {
    width: 100%;
    height: 38px;
    border: 1px solid #d8dce6;
    border-radius: 0.3rem;
  }

  &.has-icon {
    .dots {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: $color-primary;
      }
    }
  }
}

.text-extra-small {
  font-size: 12px !important;
}

.text-small {
  font-size: 14px !important;
}

.text-medium {
  font-size: 16px !important;
}
.text-large {
  font-size: 18px !important;
}

.text-extra-large {
  font-size: 20px !important;
}

.d-flex {
  display: flex;

  &.d-flex-wrap {
    flex-wrap: wrap;
  }

  &.d-flex-nowrap {
    flex-wrap: nowrap;
  }

  &.d-flex-content-center {
    justify-content: center;
  }

  &.d-flex-align-middle {
    align-items: center;
  }
}

.status-btn {
  border-radius: 6px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
}

.button-radio {
  display: flex;

  label:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  label:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.btn-secondary-light {
  color: $color-secondary !important;
  background-color: $color-secondary-light !important;
  fill: $color-secondary !important;
}

.btn-primary-light {
  color: $color-primary !important;
  background-color: $color-primary-light !important;
  fill: $color-primary !important;
}

.btn-blue-light {
  color: $color-blue !important;
  background-color: $color-blue-light !important;
  fill: $color-blue !important;
}

.btn-success-light {
  color: $color-success !important;
  background-color: $color-success-light !important;
  fill: $color-success !important;
}

.btn-violet-light {
  color: $color-violet !important;
  background-color: $color-violet-light !important;
  fill: $color-violet !important;
}

.btn-warning-light {
  color: $color-warning !important;
  background-color: $color-warning-light !important;
  fill: $color-warning !important;
}

.btn-danger-light {
  color: $color-danger !important;
  background-color: $color-danger-light !important;
  fill: $color-danger !important;
}

.btn-secondary {
  color: $color-secondary-light !important;
  background-color: $color-secondary !important;
  fill: $color-secondary-light !important;
}

.btn-primary {
  color: $color-primary-light !important;
  background-color: $color-primary !important;
  fill: $color-primary-light !important;
}

.btn-blue {
  color: $color-blue-light !important;
  background-color: $color-blue !important;
  fill: $color-blue-light !important;
}

.btn-success {
  color: $color-success-light !important;
  background-color: $color-success !important;
  fill: $color-success-light !important;
}

.btn-violet {
  color: $color-violet-light !important;
  background-color: $color-violet !important;
  fill: $color-violet-light !important;
}

.btn-warning {
  color: $color-warning-light !important;
  background-color: $color-warning !important;
  fill: $color-warning-light !important;
}

.btn-danger {
  color: $color-danger-light !important;
  background-color: $color-danger !important;
  fill: $color-danger-light !important;
}

.upload-plus {
  justify-content: center;
}
.upload-delete-icon {
  cursor: pointer;
  svg {
    width: 30px;
    height: 30px;
    fill: #fff;
  }
}

.grid-columns-5 {
  & > .ant-col {
    flex-basis: 20% !important;
    max-width: 20% !important;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.input-component-left {
  input {
    text-align: left;
  }
}

.input-component-right {
  input {
    text-align: right;
  }
}

.application-show-actions {
  &.ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: transparent;
      color: initial;
    }
  }
  .ant-menu-item {
    border-bottom: 1px solid $color-secondary-light;
    margin: 0;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.icon-grids {
  //display: grid;
  //grid-template-columns: repeat(6, minmax(0, 1fr));
  //height: 87vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: center;
  align-items: flex-start;
  border-top: 1px solid rgba($color-secondary, 0.5);
  padding: 5px;
  &-item {
    height: 100px;
    width: 20%;
    text-align: center;
    border: 1px solid rgba($color-secondary, 0.5);
    //box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 5px 0;
    font-size: 14px;
    font-weight: bolder;
    .MainIcon {
      justify-content: center;

      svg {
        fill: $color-secondary;
      }
    }

    &:hover {
      background-color: $color-primary-light;
    }
  }

  .ant-col {
    &:nth-child(6n) {
      .icon-grids-item {
        border-right-color: transparent;
      }
    }
  }
}

$transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

/* Card */
$card-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$card-shadow-hover: 0 14px 28px rgba(0, 0, 0, 0.25),
  0 10px 10px rgba(0, 0, 0, 0.22);

.selectable {
  transition: $transition;
  box-shadow: $card-shadow;
  cursor: pointer;
  &:hover {
    box-shadow: $card-shadow-hover;
  }
}

.required {
  &::after {
    content: '*';
    color: $color-danger;
    margin-left: 5px;
  }
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.image-preview {
  max-width: 104px;
  max-height: 104px;
  margin-top: 30px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border: 1px dashed $borderColor;
  }

  .image-overlay-delete {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.5);
    align-items: center;
    justify-content: center;

    svg {
      width: 14px;
      height: 16px;
    }
  }

  &:hover {
    .image-overlay-delete {
      display: flex;
    }
  }
}

.tabs {
  display: flex;
  flex-wrap: wrap;

  .tab {
    border-bottom: 2px solid transparent;
    margin-right: 15px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border-bottom-color: $color-primary;
      padding-bottom: 15px;
      color: $color-primary;
    }
  }

  &.text-small {
    .tab.active {
      padding-bottom: 5px;
    }
  }
}

.actions-list {
  .ant-menu-item {
    height: auto;
    line-height: normal;

    span {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .MainIcon {
        margin-right: 15px;
      }
    }
  }
}

.application-status {
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
}

.border-transparent {
  border-color: transparent;
}

.order-type-radio {
  .ant-radio-button-wrapper {
    padding: 13px 0;
    height: 60px;
    line-height: 30px;
    font-size: $font-small - 1px;
    margin-right: 15px;
    border-left-width: 1px;
    width: 70px;
    text-align: center;
    line-height: 25px;

    .MainIcon {
      justify-content: center;
    }

    &-checked {
      border-color: $color-primary;
      border-radius: 5px;
      color: $color-primary;

      svg {
        fill: $color-primary;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    ::before {
      content: none;
    }
  }
}

@mixin list-loop($className, $styleName, $max: 50, $offset: 5, $unit: 'px') {
  $i: 0;
  @while $i <= $max {
    #{$className + '-' + $i} {
      #{$styleName}: #{$i + $unit} !important;
    }
    $i: $i + $offset;
  }
}

$color-list: (
  black: #000,
  white: #fff,
  primary: $color-primary,
  primary-light: $color-primary-light,
  secondary: $color-secondary,
  secondary-light: $color-secondary-light,
  blue: $color-blue,
  blue-light: $color-blue-light,
  success: $color-success,
  success-light: $color-success-light,
  violet: $color-violet,
  violet-light: $color-violet-light,
  warning: $color-warning,
  warning-light: $color-warning-light,
  danger: $color-danger,
  danger-light: $color-danger-light,
);

@mixin set-color($className, $styleName, $children: '') {
  @each $name, $color in $color-list {
    #{$className + '-' + $name + ' ' + $children} {
      #{$styleName}: #{$color} !important;
    }
  }
}

@include set-color('.btn-border', 'border-color');
@include set-color('.btn-border', 'color');
@include set-color('.btn-border', 'fill', 'svg');
@include set-color('.bg', 'background-color');
@include set-color('.icon', 'fill', 'svg');
@include set-color('.text', 'color');
@include set-color('.text', 'fill', 'svg');

@include list-loop('.p', 'padding');
@include list-loop('.p-l', 'padding-left');
@include list-loop('.p-r', 'padding-right');
@include list-loop('.p-t', 'padding-top');
@include list-loop('.p-b', 'padding-bottom');

@include list-loop('.m', 'margin');
@include list-loop('.m-l', 'margin-left', 150);
@include list-loop('.m-r', 'margin-right', 150);
@include list-loop('.m-t', 'margin-top', 150);
@include list-loop('.m-b', 'margin-bottom', 150);
@include list-loop('.b-r', 'border-radius');
@include list-loop('.b-t-l-r', 'border-top-left-radius');
@include list-loop('.b-t-r-r', 'border-top-right-radius');
@include list-loop('.b-b-l-r', 'border-bottom-left-radius');
@include list-loop('.b-b-r-r', 'border-bottom-right-radius');
@include list-loop('.f-s', 'font-size', 40, 2);
@include list-loop('.width', 'width', 50, 5);
@include list-loop('.height', 'height', 50, 5);

.table-icon-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 1920px) {
  .formSearch {
    // display: none;

    .inputsSearch {
      input {
        transition: none;
        &:focus {
          width: 300px;
        }
      }
    }
  }
}

/*
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
***************** Legacy Styles ******************
***************  for refactoring *****************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
*/
.ant-table {
  background: transparent;
}

.MainTable {
  margin-left: 0;
  transition: $mainTransition;
}

.MainTable table {
  border-collapse: separate;
  border-spacing: 0 4px;

  tr th {
    user-select: none;
    padding: 0 16px;
    letter-spacing: 0.4px;
    font-size: 12px;
    color: $color-secondary;
    font-weight: normal;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 20px;
    border: 0;
    background: #fff;
    height: 38px;

    &.ant-table-column-has-sorters {
      padding: 0 5px;
      & .ant-table-column-sorter-inner .active {
        svg {
          fill: $color-primary;
        }
      }
    }
  }

  tr td {
    padding: 8px 5px;
    vertical-align: middle;
    font-size: 16px;
    position: relative;
    background: #fff;
    border: 0;

    p {
      font-size: 14px;
      color: $defaultColor;
      margin-bottom: 0;
    }

    .id span {
      line-height: 20px;
      display: block;
    }

    .id .idDown {
      color: $defaultColor;
      font-size: 14px;
    }

    .sum-success {
      color: $color-success;
    }

    .brutto span:last-child {
      color: $color-primary;
    }

    .collection-error {
      color: $color-danger;
    }

    .cargo {
      display: flex;
      align-items: center;

      svg {
        width: 32px;
      }
    }

    .cargoText {
      color: $defaultColor;
    }

    .box {
      color: $color-violet;
    }

    .status {
      line-height: 30px;
      display: block;
      width: 80%;
      border-radius: 0.5rem;
      padding: 2px 10px;
      text-align: center;
    }

    .status-travel {
      background: $color-secondary-light;
      color: $color-secondary;
    }

    .status-success {
      background: $color-success-light;
      color: $color-success;
    }

    .status-violet {
      background: $color-violet-light;
      color: $color-violet;
    }

    .status-warning {
      background: $color-violet-light;
      color: $color-violet;
    }

    .status-outlinePrimary {
      background: $color-primary;
      color: $color-primary;
    }

    .status-outlineWarning {
      background: $color-warning-light;
      color: $color-warning;
    }

    .status-info {
      background: $color-blue-light;
      color: $color-blue;
    }

    .status-danger {
      background: $color-danger-light;
      color: $color-danger;
    }

    .status-fillPrimary {
      background: #32c5ff;
      color: #fff;
    }
  }

  tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .tableCheckboxHeader {
    padding: 0 14px;
  }

  .checkmark {
    transition: $mainTransition;
    top: 0;
    bottom: 0;
    left: 10px;
    right: 0;
    margin: auto;
    background: $defaultColorCheckbox;
    border-radius: 3px;
  }

  svg {
    width: 35px;
    height: 18px;
    fill: $tableColorIcons;
  }

  th svg {
    width: 14px;
    height: 10px;
    fill: #8492b4;
  }

  .itemColSecond {
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .ant-table-column-sorters {
    padding: 0;
  }

  .ant-table-column-sorter-inner {
    margin-bottom: 2px;
  }

  .transmitter span {
    line-height: 20px;
    display: block;
  }

  .recipient span {
    line-height: 20px;
    display: block;
  }

  .flightNum span {
    line-height: 20px;
    display: block;
  }

  .transmitter span:last-child {
    color: $defaultColor;
    font-size: 14px;
  }

  .recipient span:last-child {
    color: $defaultColor;
    font-size: 14px;
  }

  .flightNum span:last-child {
    color: $defaultColor;
    font-size: 14px;
  }

  .tableActionDots {
    text-align: center;
    position: relative;
    align-items: center;
    height: 28px;
    width: 50px;
    display: flex;
  }
}

.headerTable {
  margin-bottom: 0;

  .ant-table-selection-column {
    width: 60px;
  }

  td {
    display: none;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #e5f1ff;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #fff;
}

.ant-table-tbody > tr > td.ant-table-cell {
  border-top: 2px solid $color-secondary-light;
  border-bottom: 2px solid $color-secondary-light;
}

.ant-table-tbody > tr > td.ant-table-cell:last-child {
  border-right: 2px solid transparent;
}

.ant-table-tbody > tr > td.ant-table-cell:first-child {
  border-left: 2px solid transparent;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fff;
  border-top: 2px solid $color-primary;
  border-bottom: 2px solid $color-primary;
}

.ant-table-tbody > tr.ant-table-row-selected > td:last-child {
  border-right: 2px solid $color-primary;
}

.ant-table-tbody > tr.ant-table-row-selected > td:first-child {
  border-left: 2px solid $color-primary;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.no-top-border-table .ant-table-tbody > tr > td.ant-table-cell {
  border-top: 0;
}

.modal-footer-left {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.spinnerLoader {
  border-color: rgba(173, 173, 173, 0.3);
  border-top-color: $color-primary;
  bottom: 0;
  margin: auto;
  right: 140px;
  top: 30px;
}

.headModalExtraServices {
  font-size: 20px;
  margin-bottom: 5px;
}

.ModalExtraServices {
  .ant-tabs-tab {
    padding-top: 0;
  }
}
.modalAdd {
  .delete {
    display: flex;
    border: none;
    align-items: center;
    margin-right: 0;
  }

  .trashBoxEdit {
    width: 18px;
    height: 18px;
    fill: red;
  }

  .deleteEdit {
    color: red;
    font-size: 16px;
    margin-top: 2px;
    margin-bottom: 0;
    margin-left: 5px;
  }

  .cancel {
    box-shadow: none;
    height: 38px;
    border: none;
  }

  .okAdd {
    color: white !important;
    background: $color-primary;
    height: 38px;

    .addName {
      color: white;
    }
  }

  .defaultInput input {
    width: 100%;
  }

  .ant-modal-header {
    border-bottom: none;
  }

  .chooseRow {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1vw;

    .inputNumberRow {
      width: 100%;
    }

    label {
      color: $color-secondary;
    }

    .ant-select:not(.ant-select-multiple) {
      width: 100% !important;

      .ant-select-selector {
        height: 38px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.window-center {
  position: fixed;
  top: 50%;
  width: 100%;
  height: 100%;
}

.TabPanel {
  .ant-tabs-tab {
    margin-left: 20px;
    margin-right: 5px;
  }

  .TabItem {
    padding: 0 20px;

    .selectionClass {
      width: 200px !important;
      line-height: 16px;
      align-items: center;

      .ant-select-selector {
        height: 38px;
        border-radius: $defaultBorderRadius;

        .ant-select-selection-item {
          line-height: 38px;
        }
      }
    }

    .textareaExtra {
      margin-bottom: 20px;
      margin-top: 20px;

      textarea {
        border-radius: $defaultBorderRadius;
        height: 200px;
      }
    }
  }
}

.addOrder1 {
  border: 1px solid grey;
  box-shadow: 0 0 1.5px 1.5px green;
  padding: 10px 0;
  width: 470px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    border: none;
    background: none;
    cursor: pointer;
  }
}

.loadMoreBtn {
  position: fixed;
  bottom: 7%;
  z-index: 2;
  left: 40%;
}
/*
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
***************** Legacy Styles ******************
***************  for refactoring *****************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
**************************************************
*/
